import prod from "./config-prod";
import dev from "./config-dev";

/*
const dev = {
  s3: {
    REGION: "REGION",
    BUCKET: "BUCKET"
  },
  apiGateway: {
    REGION: "REGION",
    URL: "URL"
  },
  cognito: {
    REGION: "REGION",
    USER_POOL_ID: "USER_POOL_ID",
    APP_CLIENT_ID: "APP_CLIENT_ID",
    IDENTITY_POOL_ID: "IDENTITY_POOL_ID"
  },
  STRIPE_KEY: "YOUR_STRIPE_DEV_PUBLIC_KEY"
};
*/

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "production" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  MAX_TITLE_SIZE: 120,
  MAX_URL_SIZE: 260,
  MAX_CONTENT_SIZE: 1000,
  ...config,
};
