const prod = {
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "langtago-api-prod-attachmentsbucket-z5o0q3ozqiwr",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://api.langtago.com/prod",
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_HXvj2PvSP",
    APP_CLIENT_ID: "32jlpfa5iaj5r4dbre0d3bjrg2",
    IDENTITY_POOL_ID: "ap-southeast-2:f1f418d1-1eb5-4698-9e55-a352ab914ed2",
  },
  STRIPE_KEY: "YOUR_STRIPE_PROD_PUBLIC_KEY",
};

export default prod;
